
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import { MarketStore, MMCMembersStore } from "@/store/modules";
import type { MMCMembers } from "@/store/model/mmc_members";
import { removeFilesForPatch, getNestedFormData } from "@/utils/help.utils";

@Component({
  components: { ContentHeader, AppFooter },
})
export default class MmcMemberForm extends Vue {
  headerBackgroundUrl = "about.jpg";

  editId = 0;
  pageHeading = "";
  buttonLabel = "";

  imageData: any = null;
  previewImage: any = "";

  imageInput(event: any) {
    if (event && event.target) {
      this.imageData = event.target.files[0];
      const reader = new FileReader();
      if (this.imageData) {
        reader.readAsDataURL(this.imageData);
      }
      reader.onload = (e: any) => {
        this.previewImage = e.target.result;
      };
    }
  }

  async created() {
    if (this.$route.params.id) {
      this.editId = Number(this.$route.params.id);
      await MMCMembersStore.getMMCMemberById(this.editId);
      if (this.mmcMemberDataByIdData.image) {
        this.previewImage = this.mmcMemberDataByIdData.image;
      }
    } else {
      this.mmcMemberDataByIdData = {
        id: 0,
        market: "",
        name: "",
        designation: "",
        phone_number: "",
        image: "",
      } as MMCMembers;
    }
  }

  get mmcMemberDataByIdData() {
    return MMCMembersStore.SingleMmcMember;
  }

  set mmcMemberDataByIdData(value: MMCMembers) {
    MMCMembersStore.setMmcMember(value);
  }

  setHeading() {
    this.editId === 0
      ? (this.pageHeading = "Add")
      : (this.pageHeading = "Edit");
    return this.pageHeading;
  }

  setButtonLabel() {
    this.editId === 0
      ? (this.buttonLabel = "Save")
      : (this.buttonLabel = "Edit");
    return this.buttonLabel;
  }

  async onSubmit() {
    if (this.imageData) {
      this.mmcMemberDataByIdData.image = this.imageData;
    }
    if (this.pageHeading === "Add") {
      await this.createMmc();
    } else {
      await this.editMmc(this.editId);
    }
  }

  async editMmc(id: number) {
    removeFilesForPatch([
      {
        property: this.mmcMemberDataByIdData,
        fieldName: "image",
      },
    ]);
    const editFormData = new FormData();
    const editData = getNestedFormData(
      editFormData,
      this.mmcMemberDataByIdData
    );
    await MMCMembersStore.editMMCMember({ data: editData, id: id })
      .then(() => {
        this.$q.notify({
          message: "Sucessfully Edited",
          color: "info",
        });
        this.$router.push({
          name: "MmcMemberList",
        });
      })
      .catch(() => {
        this.$q.notify({
          message: "Error occured while performing this action",
          color: "negative",
        });
      });
  }

  async createMmc() {
    const editFormData = new FormData();
    const editData = getNestedFormData(
      editFormData,
      this.mmcMemberDataByIdData
    );
    await MMCMembersStore.CreateMMCMember({ data: editData })
      .then(() => {
        this.$q.notify({
          message: "Sucessfully Edited",
          color: "info",
        });
        this.$router.push({
          name: "MmcMemberList",
        });
      })
      .catch(() => {
        this.$q.notify({
          message: "Error occured while performing this action",
          color: "negative",
        });
      });
  }

  get designationList() {
    let designations = MMCMembersStore.MmcDesignationList;
    return designations;
  }

  get marketList() {
    let markets = MarketStore.MarketList.map((x) => {
      return {
        label: x.name_en,
        value: x.id,
      };
    });
    return markets;
  }

  validatesPhoneNumber(phone_number: string) {
    return /(?:\(?\+977\)?)?[9][6-9]\d{8}|01[-]?[0-9]{7}/.test(phone_number);
  }

  async beforeMount() {
    this.mmcMemberDataByIdData.market = MarketStore.MARKETPLACE_ID;

    await MMCMembersStore.getMmcDesignation();
    await MarketStore.getMarketsList();
  }
}
